import React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useTranslation } from 'react-i18next';
import useWakeLock from '../hooks/useWakeLock';
import useThemeSwitcher from '../hooks/useThemeSwitcher';

interface ApplicationMenuComponentProps {
  testSound: () => void;
}

const ApplicationMenuComponent: React.FC<ApplicationMenuComponentProps> = ({
  testSound,
}) => {
  const { t } = useTranslation();
  const { wakeLockActive, enableWakeLock, disableWakeLock } = useWakeLock();
  const { mode, toggleTheme } = useThemeSwitcher();

  return (
    <Dropdown>
      <MenuButton
        variant="outlined"
        sx={{
          backgroundColor: mode === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.7)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <span className="material-symbols-outlined">page_info</span>
      </MenuButton>
      <Menu placement="bottom-end" sx={{ zIndex: 999 }}>
        <MenuItem
          sx={{ fontSize: '1.5rem' }}
          onClick={() => (wakeLockActive ? disableWakeLock() : enableWakeLock())}
        >
          <span className="material-symbols-outlined">
            {wakeLockActive ? 'lock_open' : 'lock'}
          </span>
          {wakeLockActive ? t('screenUnLock') : t('screenLock')}
        </MenuItem>
        <MenuItem sx={{ fontSize: '1.5rem' }} onClick={toggleTheme}>
          <span className="material-symbols-outlined">
            {mode === 'dark' ? 'light_mode' : 'dark_mode'}
          </span>
          {mode === 'dark' ? t('actionTurnLightmode') : t('actionTurnDarkmode')}
        </MenuItem>
        <MenuItem sx={{ fontSize: '1.5rem' }} onClick={testSound}>
          <span className="material-symbols-outlined">volume_up</span>
          {t('testSound')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default ApplicationMenuComponent;
