// PublicPage.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Joy Ui library
import { CssBaseline, Box } from '@mui/joy';

// Custom Hooks
import useThemeSwitcher from '../hooks/useThemeSwitcher';
import useEvent from '../hooks/useEvent';
import useAudioContextState from '../hooks/useAudioContextState';
import useNotification from '../hooks/useNotification';
import useTestSound from '../hooks/useTestSound';

// Custom components
import EventStatusSnackBarComponent from '../components/EventStatusSnackBarComponent';
import EventInfoComponent from '../components/EventInfoComponent';
import MusicPlayerComponent from '../components/MusicPlayerComponent';
import WelcomeModalComponent from '../components/WelcomeModalComponent';
import BrandFooterComponent from '../components/BrandFooterComponent';
import TestSoundModalComponent from '../components/TestSoundModalComponent';
import { Typography } from '@mui/material';

interface PublicPageProps {
  audioContext: AudioContext;
}

const PublicPage: React.FC<PublicPageProps> = ({ audioContext }) => {
  const { publicLink } = useParams<string>();

  const { mode, toggleTheme } = useThemeSwitcher();

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const { audioContextState, isWelcomeModalOpen, handleAudioContextResume } =
    useAudioContextState(audioContext);

  // Get Event information (useEventInfo / useEventAudio / useNTPTime)
  const {
    eventInfo,
    scheduledTime,
    eventError,
    ntpOffset,
    // isEventPassed,
    isSynchronized,
    playbackDelta,
    deltaScheduledWServer,
    isMusicPlaying,
    isMusicFinished,
    toggleMute,
    isMuted,
    isMusicError,
    isNetworkError,
  } = useEvent({
    eventIdentifier: publicLink!,
    audioContext,
    audioContextState,
  });

  const { isNotificationGranted, requestPermission, notify } = useNotification(
    eventInfo?.id!
  );

  const {
    isTestSoundModalOpen,
    testSound,
    closeTestSoundModal,
    error: testSoundError,
    isTestPlaying,
  } = useTestSound();

  return (
    <>
      <EventStatusSnackBarComponent
        isSynchronized={isSynchronized}
        isNetworkError={isNetworkError}
      />
      <WelcomeModalComponent
        open={isWelcomeModalOpen}
        onHandleAudioAndNotification={async () => {
          // Resume audio context when modal is closed and ask for permission & activate
          await handleAudioContextResume();
          await requestPermission();
        }}
        onHandleAudioContext={handleAudioContextResume}
        isPyro={false}
      />
      <CssBaseline />
      <Box
        sx={{
          // height: '100vh',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start', // space-between
          textAlign: 'left',
          width: '100%',
          maxWidth: '640px',
          mx: 'auto',
        }}
      >
        {/* Flex: 1 : allows to take the whole page for the eventInfoComponent */}
        <Box
          sx={{
            flex: 1,
            width: '100%',
            position: 'relative',
          }}
        >
          {ntpOffset && playbackDelta && deltaScheduledWServer && (
            <Box>
              <Typography>{'NtpOffset: ' + ntpOffset}</Typography>
              <Typography>{'playbackDelta: ' + playbackDelta}</Typography>
              <Typography>{'deltaScheduledWServer: ' + deltaScheduledWServer}</Typography>
            </Box>
          )}
          {/* Event Info Component */}
          {eventInfo && (
            <EventInfoComponent
              eventInfo={eventInfo}
              displayImage={true}
              displayDescription={true}
              shareModalOpen={shareModalOpen}
              setShareModalOpen={setShareModalOpen}
              mode={mode}
              testSound={testSound}
              isPyroPage={false}
            />
          )}
        </Box>
        {/*  partner footer */}
        <BrandFooterComponent mode={mode} showDate={false} />

        {/* Brand Footer + Musicplayer */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            position: 'sticky',
            // p: 2,
            bottom: '0',
            zIndex: 10,
            // backgroundColor: theme.palette.background.body,
          }}
        >
          {/* Music Player conditionaly rendering depending on error state */}
          {!eventError && !eventInfo?.isPassed! && (
            <>
              {/* Display only MusicPlayer if isSynchronized & no network error */}
              {isSynchronized && !isNetworkError && (
                <MusicPlayerComponent
                  isMusicPlaying={isMusicPlaying}
                  isMusicFinished={isMusicFinished}
                  isMuted={isMuted}
                  isMusicError={isMusicError}
                  scheduledTime={scheduledTime}
                  toggleMute={toggleMute}
                />
              )}
            </>
          )}
          <TestSoundModalComponent
            open={isTestSoundModalOpen}
            close={closeTestSoundModal}
            replaySound={testSound}
            isTestPlaying={isTestPlaying}
          />
        </Box>
      </Box>
    </>
  );
};

export default PublicPage;
